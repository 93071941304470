import { doc, onSnapshot } from 'firebase/firestore';
import { useState, useEffect } from 'react';
import { db } from '../firebase-config';

export function useDocument(path) {
  const [state, setState] = useState({
    data: {},
    hasLoaded: false,
  });

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, path), (document) => {
      console.log('Document Loaded', document.data());
      setState({ data: { ...document.data(), id: document.id, uid: document.id }, hasLoaded: true });
    });
    return () => {
      console.log('Document Unloaded');
      if (unsubscribe) unsubscribe();
    };
  }, [path]);

  return [state.data, state.hasLoaded];
}
