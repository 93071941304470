import React, { useContext, useEffect, useState } from 'react';
import { Box, Typography, Stack, CircularProgress } from '@mui/material';
import { addDoc, collection, doc, onSnapshot } from '@firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { useNavigate } from 'react-router-dom';
import { captureException as sentryCaptureException } from '@sentry/react';

import { db, functions } from '../../../firebase-config';
import { AuthContext } from '../../../auth-context';
import PaymentContext from '../PaymentContext';
import PromoContainer from '../misc/PromoContainer';
import { scriptPriceTranslator } from '../../../utils/form-translators';
import { capitalizeFirstLetterOfEachWord } from '../../../utils/constants';

const TillPayment = () => {
  const { user } = useContext(AuthContext);
  const { selectedTreatments, isPickup, scriptMode: isScriptMode, formName, promoCode } = useContext(PaymentContext);
  const [iframeUrl, setIframeUrl] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [isIframeLoaded, setIsIframeLoaded] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    let unsubscribe;
    const createOrderAttempt = async () => {
      const priceIds = isScriptMode ? [scriptPriceTranslator[formName]] : selectedTreatments;
      const cleanedProductIds = priceIds.map((id) => id.replace('price_', ''));
      const orderRequestedAt = Date.now();

      const orderBody = {
        updated: orderRequestedAt,
        productIds: cleanedProductIds,
        discountCode: promoCode,
        orderRequestedAt,
        isPickup,
        formName,
      };

      try {
        console.log('Creating Order Attempt...');
        // BAIL OUT if user is not authenticated
        if (!user) {
          return;
        }

        // Write document to database containing the order details
        const docRef = await addDoc(collection(db, 'patients', user.uid, 'tillOrderAttempts'), orderBody);
        const tillRequestBody = { ...orderBody, orderId: docRef.id };

        console.log('Subscribing to Order Attempt...', docRef.id);
        // Subscribe to the document for changes
        unsubscribe = onSnapshot(doc(db, 'patients', user.uid, 'tillOrderAttempts', docRef.id), (docSnapshot) => {
          const data = docSnapshot.data();
          console.log('Till Order Document Updated', data);
          if (data?.webhook?.result === 'OK') {
            navigate(data?.orderRequest?.successUrl || '/payment-success');
          }
          if (data?.webhook?.result === 'ERROR') {
            // const tillErrorCode = data?.webhook?.code ? `Error Code (${data?.webhook?.code})` : '';
            const tillErrorMessage = data?.webhook?.message ? `Error Message: ${data?.webhook?.message}` : '';
            const adapterErrorCode = data?.webhook?.adapterCode ? `Error Code (${data?.webhook?.adapterCode})` : '';
            const adapterErrorMessage = data?.webhook?.adapterMessage
              ? `Error Message: ${data?.webhook?.adapterMessage}`
              : '';
            // TODO: Handle adapterMessage in a better way
            setErrorMessage(
              `Payment was not successful. Please try again. ${adapterErrorCode} ${adapterErrorMessage || tillErrorMessage} `,
            );
          }
        });

        console.log('Calling Till Create Order...');
        // Create order request
        const { data: responseData } = await httpsCallable(functions, 'tillCreateOrder')(tillRequestBody);

        if (!responseData?.redirectUrl) {
          const message = responseData.message || 'No redirectUrl found in Till response';
          console.error('Error:', message);
          throw new Error(message);
        }

        console.log('Setting iframeUrl...');
        setIframeUrl(responseData.redirectUrl);
      } catch (error) {
        sentryCaptureException(error, {
          extra: { userId: user?.uid, body: orderBody },
        });
        setErrorMessage(error.toString());
      }
    };
    createOrderAttempt();
    return unsubscribe;
  }, [formName, isPickup, isScriptMode, navigate, promoCode, selectedTreatments, user]);

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: '500px',
        p: '30px',
        gap: '10px',
      }}
    >
      {errorMessage ? (
        <Stack>
          <Typography fontWeight="bold" color="primary">
            Sorry for the inconvenience.
          </Typography>
          <Typography maxWidth={360}>{capitalizeFirstLetterOfEachWord(errorMessage)}</Typography>
        </Stack>
      ) : (
        <>
          {isIframeLoaded && <PromoContainer />}
          <iframe
            src={iframeUrl}
            title="Till"
            style={{ width: '100%', height: '100%', display: isIframeLoaded ? 'block' : 'none', overflow: 'auto' }}
            id="till-iframe"
            onLoad={() => setIsIframeLoaded(true)}
          />
          {!isIframeLoaded && <CircularProgress />}
        </>
      )}
    </Box>
  );
};

export default TillPayment;
