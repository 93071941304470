import React, { useContext } from 'react';
import { Grid } from '@mui/material';

import PaymentContext from '../PaymentContext';
import NovattiPayment from '../novatti-ui/novatti-payment';
import TillPayment from '../till-payments-ui/TillPayment';
import { PAYMENT_PROCESSORS } from '../paymentHelpers';

const { NOVATTI, TILL } = PAYMENT_PROCESSORS;

const PaymentProcessorContainer = () => {
  const { showPaymentMethods, paymentProcessor } = useContext(PaymentContext);

  if (!showPaymentMethods) {
    return null;
  }

  return (
    <Grid
      item
      xs={12}
      md={6}
      sx={{
        margin: '5px auto',
      }}
    >
      {paymentProcessor === NOVATTI && <NovattiPayment />}
      {paymentProcessor === TILL && <TillPayment />}
    </Grid>
  );
};

export default PaymentProcessorContainer;
