const routeLoader = (factory, maxRetry = 3) =>
  new Promise((resolve, reject) => {
    factory()
      .then(resolve)
      .catch((error) => {
        if (maxRetry === 0) {
          reject(error);
          // this is probably a white page error. reload the main page
          // TODO: Handle this better, stop using window.location.reload()
          window?.location?.reload();

          return;
        }

        // NOTRE: this is a hacky way to do this. we should probably do a better job of handling this
        // recursively try again reducing the retry count
        const newMaxRetry = maxRetry - 1;
        routeLoader(factory, newMaxRetry).then(resolve, reject);
      });
  });

export default routeLoader;
