import React from 'react';
import { styled } from '@mui/material/styles';
import { Box, Snackbar, SnackbarContent } from '@mui/material';

import Navbar from '../../navigation/navbar';
import { AccountDetailsTwoFactor } from '../password-section/account-details-two-factor';

const PREFIX = 'enforce-two-factor';

const classes = {
  paperHolder: `${PREFIX}-paperHolder`,
  snackbar: `${PREFIX}-snackbar`,
  snackbarContent: `${PREFIX}-snackbarContent`,
};

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  [`& .${classes.snackbar}`]: {
    minWidth: '50px',
  },

  [`& .${classes.snackbarContent}`]: {
    backgroundColor: '#303F56',
    minWidth: '50px',
  },
}));

const EnforceTwoFactor = ({ handleLogout }) => {
  const [snackbarMessage, setSnackbarMessage] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setOpen(true);
  };
  return (
    <Root>
      <Navbar handleLogout={handleLogout} />
      <Box className={classes.paperHolder}>
        <Box sx={{ width: '32rem' }}>
          <AccountDetailsTwoFactor handleSnackbarOpen={handleSnackbarOpen} />
        </Box>
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={handleClose}
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent className={classes.snackbarContent} message={<span>{snackbarMessage}</span>} />
      </Snackbar>
    </Root>
  );
};

export default EnforceTwoFactor;
