import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Container, Typography, Button, TextField } from '@mui/material';

import { AuthContext } from '../../../../auth-context';
import { isCandor } from '../../../../utils/roles';
import { getFormsButtonText } from '../../../../utils/button-messages';

const PREFIX = 'DateFormElementPresentation';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  wrapperAcctDetails: `${PREFIX}-wrapperAcctDetails`,
  accountGridContainer: `${PREFIX}-accountGridContainer`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  errorMessage: `${PREFIX}-errorMessage`,
  input: `${PREFIX}-input`,
  button: `${PREFIX}-button`,
  chip: `${PREFIX}-chip`,
  candorHeading: `${PREFIX}-candorHeading`,
  candorSubheading: `${PREFIX}-candorSubheading`,
  candorPaper: `${PREFIX}-candorPaper`,
  candorContainer: `${PREFIX}-candorContainer`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 20,
  },

  [`&.${classes.wrapperAcctDetails}`]: {
    padding: '0',
  },

  [`& .${classes.accountGridContainer}`]: {
    height: 'auto',
    margin: '20px 0px',
  },

  [`& .${classes.paper}`]: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '40px 30px 40px 40px',
    minWidth: '500px',
    '@media (max-width: 600px)': {
      padding: '1rem',
      maxWidth: '500px',
    },
    '@media (max-width: 500px)': {
      padding: '1rem',
    },
    '@media (max-width: 450px)': {
      padding: '1rem',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.errorMessage}`]: {
    margin: '-10px 0px 20px 0px',
    color: 'red',
  },

  [`& .${classes.input}`]: {
    margin: '20px 10px',
    minWidth: 50,
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '140px',
    display: 'block',
  },

  [`& .${classes.chip}`]: {
    fontSize: '16px',
    padding: '20px',
  },

  [`& .${classes.candorHeading}`]: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: 15,
  },

  [`& .${classes.candorSubheading}`]: {
    textAlign: 'left',
    margin: '0 0px 10px 0px',
  },

  [`& .${classes.candorPaper}`]: {
    boxShadow: 'unset',
    borderBottom: '1px solid rgb(0 0 0 / 20%)',
    borderRadius: 'unset',
    padding: '0px 0 20px',
    width: '100%',
    maxWidth: '600px',
    '@media (max-width: 600px)': {
      padding: '1rem',
    },
  },

  [`& .${classes.candorContainer}`]: {
    justifyContent: 'left',
    padding: 0,
  },
}));

// Component to be rendered
const DateFormElementPresentation = (props) => {
  const {
    isLoading,
    isInputValidLength,
    throwError,
    handleTextInput,
    currentFormObject,
    handleInput,
    inputValue,
    simpleView,
    isUpdateDetails,
  } = props;

  const { userType } = useContext(AuthContext);

  const buttonText = getFormsButtonText({ isUpdateDetails, isLoading });

  // combine the day, month and year into a single string
  const displayDateString = `${inputValue.day}/${inputValue.month}/${inputValue.year}`;
  return (
    <StyledContainer maxWidth="lg" className={classes.wrapperAcctDetails}>
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        className={isCandor(userType) ? classes.accountGridContainer : classes.gridContainer}
      >
        <Paper className={simpleView ? classes.candorPaper : classes.paper}>
          <form>
            <div>
              <Typography
                className={simpleView ? classes.candorHeading : classes.heading}
                variant={simpleView ? 'body1' : 'h5'}
                align="center"
                gutterBottom
              >
                {currentFormObject.headingText}
              </Typography>
            </div>
            <div>
              <Container className={simpleView ? classes.candorContainer : classes.container}>
                {simpleView ? (
                  <Typography className={classes.candorAnswer}>{displayDateString}</Typography>
                ) : (
                  <>
                    <TextField
                      type="text"
                      variant="outlined"
                      className={classes.input}
                      onChange={(e) => handleTextInput(e.target.value, 'day')}
                      value={inputValue.day}
                      label="DD"
                      name="day"
                      autoComplete="off"
                    />
                    <TextField
                      type="text"
                      variant="outlined"
                      className={classes.input}
                      onChange={(e) => handleTextInput(e.target.value, 'month')}
                      value={inputValue.month}
                      label="MM"
                      name="month"
                      autoComplete="off"
                    />
                    <TextField
                      type="text"
                      variant="outlined"
                      className={classes.input}
                      onChange={(e) => handleTextInput(e.target.value, 'year')}
                      value={inputValue.year}
                      label="YYYY"
                      name="year"
                      autoComplete="off"
                    />
                  </>
                )}
              </Container>
              <Typography className={classes.errorMessage} variant="subtitle1" align="center" gutterBottom>
                {!throwError ? '' : 'Please enter a valid date'}
              </Typography>
            </div>
            {(!isCandor(userType) || isUpdateDetails) && (
              <div>
                <Button
                  disabled={!isInputValidLength || isLoading}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleInput}
                >
                  {buttonText}
                </Button>
              </div>
            )}
          </form>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default DateFormElementPresentation;
