import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import {
  Grid,
  Paper,
  Container,
  Typography,
  Button,
  TextField,
  Box,
  FormControlLabel,
  Radio,
  Divider,
} from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { AuthContext } from '../../../../auth-context';

const PREFIX = 'TreatmentsTriedFormElementPresentation';

const classes = {
  root: `${PREFIX}-root`,
  gridContainer: `${PREFIX}-gridContainer`,
  box: `${PREFIX}-box`,
  paper: `${PREFIX}-paper`,
  capitalizeHeading: `${PREFIX}-capitalizeHeading`,
  heading: `${PREFIX}-heading`,
  altHeading: `${PREFIX}-altHeading`,
  subheading: `${PREFIX}-subheading`,
  radioGrid: `${PREFIX}-radioGrid`,
  label: `${PREFIX}-label`,
  checkbox: `${PREFIX}-checkbox`,
  input: `${PREFIX}-input`,
  outlinedButtons: `${PREFIX}-outlinedButtons`,
  openAdderButton: `${PREFIX}-openAdderButton`,
  removeButton: `${PREFIX}-removeButton`,
  button: `${PREFIX}-button`,
  helperText: `${PREFIX}-helperText`,
  simpleContainer: `${PREFIX}-simpleContainer`,
  simpleSubHeading: `${PREFIX}-simpleSubHeading`,
  simpleAltHeading: `${PREFIX}-simpleAltHeading`,
  simpleViewAnswer: `${PREFIX}-simpleViewAnswer`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 20,
  },

  [`& .${classes.box}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
  },

  [`& .${classes.capitalizeHeading}`]: {
    textTransform: 'capitalize',
    marginBottom: 30,
  },

  [`& .${classes.heading}`]: {
    textTransform: null,
    marginBottom: 30,
  },

  [`& .${classes.altHeading}`]: {
    margin: '20px 0px 5px 0px',
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 5px 0px',
  },

  [`& .${classes.radioGrid}`]: {
    marginBottom: 20,
  },

  [`& .${classes.label}`]: {
    margin: '10px',
    padding: '8px 10px',
    textAlign: 'center',
    width: '150px',
    [theme.breakpoints.down('xl')]: {
      width: '100px',
    },
  },

  [`& .${classes.checkbox}`]: {
    display: 'none',
  },

  [`& .${classes.input}`]: {
    margin: '10px 10px',
    maxWidth: '370px',
    // Capitalize the labels
    '& .MuiFormLabel-root': {
      textTransform: 'capitalize',
    },
  },

  [`& .${classes.outlinedButtons}`]: {
    margin: '10px',
    width: 80,
  },

  [`& .${classes.openAdderButton}`]: {
    margin: '30px',
    padding: 10,
  },

  [`& .${classes.removeButton}`]: {
    margin: '10px 5px 10px 0px',
    padding: '15px 10px',
    minWidth: 70,
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '130px',
    display: 'block',
  },

  [`& .${classes.helperText}`]: {
    textAlign: 'center',
  },

  [`& .${classes.simpleContainer}`]: {
    justifyContent: 'left',
    padding: 0,
    maxWidth: '600px',
  },

  [`& .${classes.simpleSubHeading}`]: {
    marginTop: 10,
    fontSize: '16px',
    textAlign: 'left',
    fontWeight: 'bold',
  },

  [`& .${classes.simpleAltHeading}`]: {
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'left',
  },

  [`& .${classes.simpleViewAnswer}`]: {
    textAlign: 'left',
    marginBottom: 10,
  },
}));

const StyledFormControlLabel = FormControlLabel;

const TreatmentsTriedFormElementPresentation = (props) => {
  const {
    currentFormObject,
    simpleView,
    handleNext,
    handleSaveChanges,
    newTreatmentObj,
    editingExistingElement,
    slimTextOne,
    slimTextTwo,
    radioOne,
    radioTwo,
    longTextOne,
    longTextTwo,
    elementAdder,
    handleEditElement,
    handleRadioOneChange,
    handleRadioTwoChange,
    handleCancel,
    handleElementAdder,
    inputError,
    handleLongOneChange,
    handleLongTwoChange,
    handleSlimOneChange,
    handleSlimTwoChange,
    handleRemoveElement,
    options,
  } = props;

  const { userType } = useContext(AuthContext);

  return (
    <StyledContainer maxWidth="lg" align="center">
      <Grid
        container
        className={simpleView ? classes.simpleContainer : classes.gridContainer}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        {simpleView ? (
          Object.keys(options).map((option, ind) => {
            const duration = '.duration';
            const details = '.details';
            return (
              <div style={{ width: '100%' }} key={ind}>
                <Typography className={classes.simpleSubHeading} variant="h6" align="center" gutterBottom>
                  Treatment Name
                </Typography>
                <Typography className={classes.simpleViewAnswer}>{option}</Typography>
                {options[option][duration] && (
                  <>
                    <Typography className={classes.simpleAltHeading} variant="h6" align="center" gutterBottom>
                      How long did you try it for?
                    </Typography>
                    <Typography className={classes.simpleViewAnswer}>{options[option][duration]}</Typography>
                  </>
                )}
                <Typography className={classes.simpleAltHeading} variant="h6" align="center" gutterBottom>
                  Did this treatment work?
                </Typography>
                <Typography className={classes.simpleViewAnswer}>
                  {options[option]['.was effective'] === true ? 'Yes' : 'No'}
                </Typography>
                <Typography className={classes.simpleAltHeading} variant="h6" align="center" gutterBottom>
                  Did you have any side effects?
                </Typography>
                <Typography className={classes.simpleViewAnswer}>
                  {options[option]['.side effects'] === true ? 'Yes' : 'No'}
                </Typography>
                {options[option]['.side effects'] === true && (
                  <>
                    <Typography className={classes.simpleAltHeading} variant="h6" align="center" gutterBottom>
                      What side effects did you experience?
                    </Typography>
                    <Typography className={classes.simpleViewAnswer}>
                      {options[option]['.side effects details']}
                    </Typography>
                  </>
                )}
                {options[option][details] &&
                  options[option]['.was effective'] === true &&
                  options[option]['.side effects'] === false && (
                    <>
                      <Typography className={classes.simpleAltHeading} variant="h6" align="center" gutterBottom>
                        Why don&apos;t you want to use this treatment?
                      </Typography>
                      <Typography className={classes.simpleViewAnswer}>{options[option][details]}</Typography>
                    </>
                  )}
                <Divider
                  sx={{
                    opacity: '0.6',
                  }}
                />
              </div>
            );
          })
        ) : (
          <Paper className={classes.paper}>
            <form>
              {!elementAdder && (
                <div>
                  <Typography
                    className={currentFormObject.capitalizeHeading ? classes.capitalizeHeading : classes.heading}
                    variant="h5"
                    align="center"
                    gutterBottom
                  >
                    {currentFormObject.headingText}
                  </Typography>
                  <Typography variant="body2" align="center" gutterBottom>
                    {currentFormObject.subheadingText}
                  </Typography>
                  <Box align="center">
                    <Button
                      variant="outlined"
                      className={classes.openAdderButton}
                      color="primary"
                      onClick={handleElementAdder}
                    >
                      Add Treatment
                    </Button>
                  </Box>
                </div>
              )}
              {elementAdder && (
                <div>
                  <Typography className={classes.subheading} variant="h6" align="center" gutterBottom>
                    Treatment Name
                  </Typography>
                  <Box className={classes.box}>
                    <TextField
                      type="text"
                      variant="outlined"
                      multiline
                      className={classes.input}
                      onChange={(e) => handleSlimOneChange(e.target.value)}
                      value={newTreatmentObj[slimTextOne]}
                      InputLabelProps={{ required: false }}
                      error={!(inputError === '')}
                      helperText={inputError}
                      FormHelperTextProps={{ className: classes.helperText }}
                      fullWidth
                    />
                  </Box>
                  {slimTextTwo && (
                    <>
                      <Typography className={classes.altHeading} variant="h6" align="center" gutterBottom>
                        How long did you try it for?
                      </Typography>
                      <Box className={classes.box}>
                        <TextField
                          type="text"
                          variant="outlined"
                          multiline
                          className={classes.input}
                          onChange={(e) => handleSlimTwoChange(e.target.value)}
                          value={newTreatmentObj[slimTextTwo]}
                          InputLabelProps={{ required: false }}
                          FormHelperTextProps={{ className: classes.helperText }}
                          fullWidth
                        />
                      </Box>
                    </>
                  )}
                  <Typography className={classes.altHeading} variant="h6" align="center" gutterBottom>
                    Did this treatment work?
                  </Typography>
                  <Grid item sm={12} className={classes.radioGrid}>
                    <StyledFormControlLabel
                      control={
                        <Radio
                          checked={newTreatmentObj[radioOne] === true}
                          onChange={() => {
                            handleRadioOneChange(true);
                          }}
                          className={classes.checkbox}
                        />
                      }
                      label={
                        <Box border={2} borderColor="primary.main" className={classes.label} borderRadius="8px">
                          {' '}
                          Yes
                        </Box>
                      }
                      classes={{
                        root: classes.root,
                      }}
                    />
                    <StyledFormControlLabel
                      control={
                        <Radio
                          checked={newTreatmentObj[radioOne] === false}
                          onChange={() => {
                            handleRadioOneChange(false);
                          }}
                          className={classes.checkbox}
                        />
                      }
                      label={
                        <Box border={2} borderColor="primary.main" className={classes.label} borderRadius="8px">
                          {' '}
                          No
                        </Box>
                      }
                      classes={{
                        root: classes.root,
                      }}
                    />
                  </Grid>
                  <Typography className={classes.altHeading} variant="h6" align="center" gutterBottom>
                    Did you have any side effects?
                  </Typography>
                  <Grid item sm={12} className={classes.radioGrid}>
                    <StyledFormControlLabel
                      control={
                        <Radio
                          checked={newTreatmentObj[radioTwo] === true}
                          onChange={() => {
                            handleRadioTwoChange(true);
                          }}
                          className={classes.checkbox}
                        />
                      }
                      label={
                        <Box border={2} borderColor="primary.main" className={classes.label} borderRadius="8px">
                          {' '}
                          Yes
                        </Box>
                      }
                      classes={{
                        root: classes.root,
                      }}
                    />
                    <StyledFormControlLabel
                      control={
                        <Radio
                          checked={newTreatmentObj[radioTwo] === false}
                          onChange={() => {
                            handleRadioTwoChange(false);
                          }}
                          className={classes.checkbox}
                        />
                      }
                      label={
                        <Box border={2} borderColor="primary.main" className={classes.label} borderRadius="8px">
                          {' '}
                          No
                        </Box>
                      }
                      classes={{
                        root: classes.root,
                      }}
                    />
                  </Grid>
                  {newTreatmentObj[radioTwo] === true && (
                    <>
                      <Typography className={classes.altHeading} variant="h6" align="center" gutterBottom>
                        What side effects did you experience?
                      </Typography>
                      <Box className={classes.box}>
                        <TextField
                          type="text"
                          variant="outlined"
                          multiline
                          rows={3}
                          className={classes.input}
                          onChange={(e) => handleLongOneChange(e.target.value)}
                          value={newTreatmentObj[longTextOne]}
                          InputLabelProps={{ required: false }}
                          fullWidth
                        />
                      </Box>
                    </>
                  )}
                  {longTextTwo && newTreatmentObj[radioOne] === true && newTreatmentObj[radioTwo] === false && (
                    <>
                      <Typography className={classes.altHeading} variant="h6" align="center" gutterBottom>
                        Why don&apos;t you want to use this treatment?
                      </Typography>
                      <Box className={classes.box}>
                        <TextField
                          type="text"
                          variant="outlined"
                          multiline
                          rows={3}
                          className={classes.input}
                          onChange={(e) => handleLongTwoChange(e.target.value)}
                          value={newTreatmentObj[longTextTwo]}
                          InputLabelProps={{ required: false }}
                          fullWidth
                        />
                      </Box>
                    </>
                  )}
                  <Box align="center">
                    {!editingExistingElement && (
                      <>
                        <Button
                          className={classes.outlinedButtons}
                          variant="outlined"
                          color="primary"
                          onClick={() => handleCancel()}
                        >
                          Cancel
                        </Button>
                        <Button
                          className={classes.outlinedButtons}
                          variant="outlined"
                          disabled={
                            !newTreatmentObj[slimTextOne] ||
                            newTreatmentObj[radioOne] === null ||
                            newTreatmentObj[radioTwo] === null ||
                            (newTreatmentObj[radioTwo] && !newTreatmentObj[longTextOne]) ||
                            (newTreatmentObj[longTextTwo] !== undefined &&
                              !newTreatmentObj[longTextTwo] &&
                              newTreatmentObj[radioOne] &&
                              !newTreatmentObj[radioTwo])
                          }
                          color="primary"
                          onClick={handleSaveChanges}
                        >
                          Save
                        </Button>
                      </>
                    )}
                    {editingExistingElement && userType === 'patient' && (
                      <>
                        <Button
                          className={classes.outlinedButtons}
                          variant="outlined"
                          color="primary"
                          onClick={handleRemoveElement}
                        >
                          Remove
                        </Button>
                        <Button
                          className={classes.outlinedButtons}
                          variant="outlined"
                          disabled={
                            !newTreatmentObj[slimTextOne] ||
                            newTreatmentObj[radioOne] === null ||
                            newTreatmentObj[radioTwo] === null ||
                            (newTreatmentObj[radioTwo] && !newTreatmentObj[longTextOne]) ||
                            (newTreatmentObj[longTextTwo] !== undefined &&
                              !newTreatmentObj[longTextTwo] &&
                              newTreatmentObj[radioOne] &&
                              !newTreatmentObj[radioTwo])
                          }
                          color="primary"
                          onClick={handleSaveChanges}
                        >
                          Save
                        </Button>
                      </>
                    )}
                    {editingExistingElement && (userType === 'doctor' || userType === 'nurse') && (
                      <Button
                        className={classes.outlinedButtons}
                        variant="outlined"
                        color="primary"
                        onClick={handleSaveChanges}
                      >
                        Close
                      </Button>
                    )}
                  </Box>
                </div>
              )}
              {Object.keys(options).map((option, ind) => (
                <div key={ind}>
                  {!elementAdder && (
                    <Box key={ind} className={classes.box}>
                      <CheckCircleRoundedIcon color="primary" />
                      <TextField
                        disabled
                        type="text"
                        variant="outlined"
                        multiline
                        className={classes.input}
                        value={option}
                        label="Treatment"
                        InputLabelProps={{ required: false }}
                        fullWidth
                        required
                      />
                      <Button
                        variant="outlined"
                        className={classes.removeButton}
                        value={option}
                        onClick={() => handleEditElement(option)}
                        color="primary"
                      >
                        {userType === 'doctor' || userType === 'nurse' ? 'View' : 'Edit'}
                      </Button>
                    </Box>
                  )}
                </div>
              ))}
              {!elementAdder && (
                <Button
                  disabled={!Object.keys(options)[0]}
                  className={classes.button}
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </form>
          </Paper>
        )}
      </Grid>
    </StyledContainer>
  );
};

export default TreatmentsTriedFormElementPresentation;
