import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { Modal as MuiModal } from '@mui/material';

// NOTE: There needs to be a better patterm as we break
// multiple components on small iphone devices on edge cases.
const StyledMuiModal = styled(MuiModal)({
  // height: '95vh',
  // top: '2%',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  padding: '1rem',
  overflow: 'hidden',
  alignItems: 'center',
  alignContent: 'space-around',
  justifyContent: 'flex-start',
});

const StyledModal = ({ isOpen = false, slotProps = {}, children, ..._props }) => (
  <StyledMuiModal
    data-testid="default-styled-mui-modal"
    open={isOpen}
    slotProps={slotProps}
    closeAfterTransition
    // eslint-disable-next-line react/jsx-props-no-spreading
    {..._props}
  >
    {children}
  </StyledMuiModal>
);

StyledModal.propTypes = {
  children: PropTypes.node,
  slotProps: PropTypes.shape({}),
  isOpen: PropTypes.bool,
};

StyledModal.defaultProps = {
  children: null,
  slotProps: {},
  isOpen: false,
};

export default StyledModal;
