import { captureMessage as sentryCaptureMessage } from '@sentry/react';
import { getDoc, doc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { logPurchaseEvent } from '../../analytics/logAnalyticsEvent';

import { db, functions } from '../../firebase-config';

// Maps to Catalogue.name in Firestore
export const SERVICES = {
  CONSULT: 'consultation',
  TRANSFER_SCRIPT: 'script admin fee',
  BOOKING_FEE: 'booking fee',
};

export const PAYMENT_URLS = {
  [SERVICES.CONSULT]: '/consult-payment-success',
  [SERVICES.BOOKING_FEE]: '/booking-payment-success',
  [SERVICES.TRANSFER_SCRIPT]: '/script-payment-success',
  default: '/payment-success',
};

/**
 * Get success page URL based on product name.
 * @param {object} params - Parameters.
 * @param {string} params.productName - Name of the product.
 * @param {boolean} [params.isScriptMode=false] - Indicates if the script mode is active.
 * @returns {string} - URL of the success page.
 */
export const getPaymentSuccessUrl = ({ productName, isScriptMode = false }) => {
  // QUESTION: FIXME: Why don't we check for "script admin fee"/SERVICES.TRANSFER_SCRIPT
  // instead of allowing scritMode to override the default?
  if (isScriptMode) {
    return PAYMENT_URLS[SERVICES.TRANSFER_SCRIPT];
  }

  return PAYMENT_URLS[productName] || PAYMENT_URLS.default;
};

/**
 * Logs the first consultation purchase event. Will only log if productPurchased is 'consult'.
 *
 * @param {Object} options - The options for logging the event.
 * @param {string} options.productPurchased - The product purchased.
 * @param {Array} options.products - The list of products.
 * @param {string} options.userId - The user ID.
 */
export const logFirstConsultation = ({ productPurchased, products = [], userId }) => {
  const consultation = products?.find((product) => product.name === SERVICES.CONSULT) || {
    price: 0,
    name: 'UNKNOWN',
    form: 'UNKNOWN',
    stripePrice: 'NOT_A_CONSULTATION',
  };

  const catalogId = consultation.stripePrice.replace('price_', '');

  if (productPurchased !== 'consult') {
    sentryCaptureMessage('[logFirstConsultation] - productPurchased is not a consultation', {
      level: 'debug',
      extra: { productPurchased, userId },
    });
    return;
  }

  logPurchaseEvent({
    userId,
    catalogId,
    value: consultation.price,
    itemsPurchased: [
      { itemId: catalogId, itemName: `${consultation.form} ${consultation.name}`, price: consultation.price },
    ],
  });
};

export const PAYMENT_PROCESSORS = {
  NOVATTI: 'novatti',
  TILL: 'till',
};

/**
 * Get the payment processor in firestore DB /public/payment_data document.
 * @returns {string} - payment processor string.
 */
export const getPaymentProcessor = async () =>
  (await getDoc(doc(db, 'public', 'payment_data'))).data()?.paymentProcessor || '';

export const refundProcessor = async ({ functionName, data }) => {
  const paymentProcessor = await getPaymentProcessor();
  if (paymentProcessor !== PAYMENT_PROCESSORS.NOVATTI && paymentProcessor !== PAYMENT_PROCESSORS.TILL) {
    return { invalid: true, message: 'Invalid Payment Processor' };
  }

  try {
    return await httpsCallable(functions, functionName)(data);
  } catch (error) {
    return { error: true, message: error.message };
  }
};
