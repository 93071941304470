import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import {
  Drops,
  Lotion,
  Spray,
  Tablets,
  Injectable,
  SprayPlusDrops,
  TabletsPlusLotion,
  TabletsPlusSpray,
} from '../media/prescriptions';

// temporary solution until decided where these should be placed
const NOT_PRODUCTS_TYPES = ['consultation', 'script admin fee', 'booking fee'];

export const APP_BASE_URL = 'https://app.candormedical.com';

export const AUSPOST_BASE_TRACKING_URL = 'https://auspost.com.au/mypost/track/#/details/';

export const ONE_DAY_IN_MS = 86400000;

export const getTotalTimeStamp = (days) => ONE_DAY_IN_MS * days;

export const TEN_DAYS_IN_MS = getTotalTimeStamp(10);

export const ALL_PHARMACIES = 'All Pharmacies';

export const CANDOR = 'Candor';

export const DATE = 'date';

export const SUPPORT_ID = import.meta.env.VITE_APP_SUPPORT_UID;
export const SUPPORT_EMAIL_ADDRESS = 'support@candor.org';

export const SHIPPING_CHARGE_CHANGE_DATE_UNIX_TIMESTAMP_MS = 1701602636000; // 2023-12-03 shipping charge change from 16.5 to 15
export const RATE_BEFORE_CHANGE_DATE = 16.5;
export const RATE_AFTER_CHANGE_DATE = 15;

/**
 * Represents the number of milliseconds in one month.
 * @constant {number} ONE_MONTH_IN_MS - 28 days in milliseconds
 */
export const ONE_MONTH_IN_MS = getTotalTimeStamp(28);

export const FORMLIST = {
  AGEING: 'ageing',
  ACNE: 'acne',
  ALLERGIES: 'allergies',
  COLD_SORES: 'cold sores',
  CONTRACEPTIVE_PILL: 'contraceptive pill',
  MEDICINAL_CANNABIS: 'medicinal cannabis',
  ERECTILE_DYSFUNCTION: 'erectile dysfunction',
  NICOTINE: 'nicotine',
  HERPES: 'herpes',
  HAIR_LOSS: 'hair loss',
  SLEEP: 'sleep',
  WEIGHT_LOSS: 'weight loss',
};
export const FORMS = Object.values(FORMLIST);
export const UNISEXUAL_FORMS = FORMS.filter(
  (form) => ![FORMLIST.CONTRACEPTIVE_PILL, FORMLIST.ERECTILE_DYSFUNCTION].includes(form),
);
export const getValidForms = (formArray) => {
  if (!formArray || !formArray.length) {
    return [];
  }

  return formArray.reduce((acc, form) => {
    if (!FORMS.includes(form) || form === FORMLIST.NICOTINE) {
      return acc;
    }
    return [...acc, form];
  }, []);
};

export const GENDER = {
  MALE: 'Male',
  FEMALE: 'Female',
};

export const BOOKING_STATUS = {
  AWAITNG_BOOKING_PAYMENT: 'awaiting booking payment',
  AWAITNG_INTERVIEW: 'awaiting interview',
  AWAITNG_SCHEDULE: 'awaiting schedule',
};

// Form Status
// NOTE: Probably missed some statuses, please add them as needed
export const FORM_STATUS = {
  IN_PROGRESS: 'in progress',
  AWAITING_REVIEW: 'awaiting review',
  AWAITING_SCRIPT: 'awaiting script',
  AWAITING_PAYMENT: 'awaiting payment',
  CONSULT_COMPLETE: 'consult complete',
  AWAITING_UPLOAD: 'awaiting upload',
};

export const formatErrorMessage = (error) =>
  `${error}, please refresh the page and try again. If the problem still persists, please contact Candor support.`;

export const isAnActualProduct = (itemName) => !NOT_PRODUCTS_TYPES.includes(itemName);

export const getAllPharmacyNamesById = async () => {
  const querySnapshot = await getDocs(query(collection(db, 'orders'), where('type', '==', 'pharmacy')));
  return querySnapshot.docs.reduce((acc, item) => ({ ...acc, [item.id]: item.data().name }), {});
};

export const SUBSTITUTION_PAYMENT_MODE = {
  IN_STORE: 'in-store-payment',
  ONLINE: 'online-payment',
};

export const capitalizeFirstLetterOfEachWord = (words = '') => {
  if (typeof words !== 'string') {
    return '';
  }

  return words
    .split(' ')
    .map((word) => (word ? `${word[0].toUpperCase()}${word.substring(1)}` : ''))
    .join(' ');
};

export const allowedUserType = (allowedUserTypesArray, userType) => allowedUserTypesArray.includes(userType);

export const extractNumericCharacters = (value = '') => value?.replace(/[^0-9]/g, '');

export const getCatalogueId = (id) => (id ? id.replace(/^price_/, '') : '');

export const getUserTypeValidity = ({ userType, validUsers }) => {
  if (!userType || !Array.isArray(validUsers) || !validUsers?.length) {
    return false;
  }
  return validUsers.includes(userType);
};

export const USER_TYPES = {
  DOCTOR: 'doctor',
  NURSE: 'nurse',
  PHARMACIST: 'pharmacist',
  DIRECTOR: 'director',
  PATIENT: 'patient',
  SUPPORT: 'support',
  SUPPLIER: 'supplier',
};

export const SHIPMENT_TYPES = {
  COMPLETE: 'complete',
  COMPLETED: 'completed',
  OUTSTANDING: 'outstanding',
  SHIPMENT_REQUIRED: 'shipment required',
  PICKUP_REQUIRED: 'pickup required',
  SCRIPT_ONLY: 'script only',
  ARCHIVED: 'archived',
};

export const cleanedArray = (arr) => arr?.filter((item) => item);

export const convertTimestampToString = (timestamp) => {
  const date = new Date(timestamp);
  const dateString = `${date.getDate() < 10 ? '0' : ''}${date.getDate()}/${date.getMonth() < 9 ? '0' : ''}${
    date.getMonth() + 1
  }/${date.getFullYear() - 2000}`;
  return dateString;
};

export const PAYMENT_METHODS = {
  INVOICE: 'invoice',
  CREDIT_CARD: 'credit card',
};

export const findDataById = ({ data, id }) => data.find((item) => item.id === id);

export const formatCurrency = ({ value, lastValue, maxValue }) =>
  Number(value) >= 0 &&
  Number(value) <= maxValue &&
  (String(value).includes('.') ? String(value).split('.')[1].length <= 2 : true)
    ? value
    : lastValue;

export const STATES = {
  NSW: 'New South Wales',
  VIC: 'Victoria',
  QLD: 'Queensland',
  SA: 'South Australia',
  WA: 'Western Australia',
  TAS: 'Tasmania',
  NT: 'Northern Territory',
  ACT: 'Australian Capital Territory',
};

export const formatCamelCase = (string) =>
  typeof string === 'string' ? string.replace(/([A-Z])/g, ' $1').toLowerCase() : '';

// remove all non-numeric characters from the input field with max characters set by limit
export const getFormattedInteger = ({ value, limit }) => {
  if (!value || !limit || typeof value !== 'string' || typeof limit !== 'number') {
    return '';
  }
  return value.replace(/[^0-9]/g, '').substring(0, limit);
};

// Product Filters and Sorts for Catalogue
export const BASIC_FILTER = {
  ALL: 'all',
  ASSIGNED: 'assigned',
  FAVOURITES: 'favourites',
  PENDING_APPROVAL: 'pendingApproval',
  SUGGESTED: 'suggested',
};

export const PRODUCT_SORT_ITEMS = [
  { label: 'Default', value: 'products/sort/suggested:desc,name:asc' },
  { label: 'Alphabetical', value: 'products/sort/name:asc' },
  { label: 'Price (asc)', value: 'products/sort/price:asc' },
  { label: 'Price (desc)', value: 'products/sort/price:desc' },
];

// NOTE: YOU CAN ONLY CHANGE 20, 40, 100, 250 BASED ON THE OPTIONS IN CustomSort.jsx
// VALUES OTHER THAN THESE WILL GIVEN AN ERROR
export const DEFAULT_HITS_PER_PAGE = 20;

export const POPULATARITY_SORT_ITEM = { label: 'Popularity (desc)', value: 'products/sort/popularity:desc' };

export const ICONLIST = {
  TABLETS: 'Tablets',
  DROPS: 'Drops',
  LOTION: 'Lotion',
  SPRAY: 'Spray',
  INJECTABLE: 'Injectable',
  SPRAY_PLUS_DROPS: 'SprayPlusDrops',
  TABLETS_PLUS_LOTION: 'TabletsPlusLotion',
  TABLETS_PLUS_SPRAY: 'TabletsPlusSpray',
};

// add the newly created icons here in the future
export const ICON_SVGS = {
  Tablets,
  Drops,
  Lotion,
  Spray,
  Injectable,
  SprayPlusDrops,
  TabletsPlusLotion,
  TabletsPlusSpray,
};

export const convertToCurrency = (amount) =>
  `$${Number(amount)
    .toFixed(2)
    .replace(/\d(?=(\d{3})+\.)/g, '$&,')}`;

export const isBrandInList = ({ brandList, brand }) => {
  if (!Array.isArray(brandList) || !brandList.length || typeof brand !== 'string' || !brand) {
    return false;
  }
  return brandList.some((itemBrand) => itemBrand.toLowerCase() === brand.toLowerCase());
};
