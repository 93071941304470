import React, { useState, useContext, useEffect } from 'react';
import { doc, updateDoc, deleteField, writeBatch, collection } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import Loading from '../../../layout/loading';
import { isCandor } from '../../../../utils/roles';
import { AuthContext } from '../../../../auth-context';
import { processFieldData } from '../../../../utils/formHelpers';
import createEmailObj from '../../../../functions/create-email-obj';
import ExpandableFormElementPresentation from '../presentation-elements/expandable-form-element-presentation';

const ExpandableFormElement = (props) => {
  const { currentFormObject, handlePrev, setFormInputs, handleNext, formInputs, formName, index, simpleView } = props;

  const { user, userType } = useContext(AuthContext);

  const [inputError, setInputError] = useState('');
  const [options, setOptions] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);

  const [inputOne, inputTwo] = currentFormObject.inputs;

  const prepender = `${inputOne}.`;
  const appender = `.${inputTwo}`;

  const clearError = () => {
    if (inputError !== '') setInputError('');
  };

  const handleRemoveElement = async (removalKey) => {
    const key = prepender + removalKey + appender;
    const overviewKey = [prepender + removalKey + appender];
    // Remove the item from the inputs array
    clearError();
    setOptions(options.filter((option) => option !== removalKey));
    // Remove the item from the greater form inputs array
    setFormInputs(() => {
      const newFormInputs = { ...formInputs };
      newFormInputs[overviewKey] = false;
      delete newFormInputs[key];
      return newFormInputs;
    });

    if (!user) {
      return;
    }

    const deletePath = `${prepender}${removalKey}`;
    // Let the GP know about the change and update the patient activity
    if (formInputs['basic info.first form completed']) {
      const dataObj = {
        patientName: formInputs['basic info.first name'] + formInputs['basic info.last name'],
        email: user.email,
        updatedData: `Patient removed an item from their ${inputOne} list`,
      };
      const batch = writeBatch(db);
      const mailRef1 = doc(collection(db, 'mail'));
      batch.set(mailRef1, createEmailObj('nurse@candor.org', 'medical_history_updated', dataObj));
      batch.update(
        doc(db, 'patients', user.uid, 'general', 'information'),
        processFieldData(deletePath),
        deleteField(),
      );
      batch.set(doc(collection(db, 'patients', user.uid, 'activity')), {
        createdAt: Date.now(),
        generalData: false,
        author: 'System',
        text: `Patient removed ${removalKey} from their ${inputOne} list`,
      });
      await batch.commit();
    } else {
      updateDoc(doc(db, 'patients', user.uid, 'general', 'information'), processFieldData(deletePath), deleteField());
    }
  };

  const handleAddElement = async (nextButton) => {
    const lastOption = options[0];
    let counts = 0;
    options.forEach((option) => {
      if (option === options[0]) counts += 1;
    });
    if (counts > 1) {
      setInputError(`This ${inputOne.toLowerCase()} has already been entered.`);
    } else {
      setOptions(['', ...options]);
      const obj = { [prepender + lastOption + appender]: true };
      setFormInputs({ ...formInputs, ...obj });
      // Let the GP know about the change and update the patient activity
      if (!isCandor(userType)) obj[`forms.${formName}.step`] = index;
      if (formInputs['basic info.first form completed']) {
        const dataObj = {
          patientName: `${formInputs['basic info.first name']} ${formInputs['basic info.last name']}`,
          email: user?.email,
          updatedData: `Patient updated their ${prepender} list`,
        };
        const batch = writeBatch(db);
        const mailRef1 = doc(collection(db, 'mail'));
        batch.set(mailRef1, createEmailObj('nurse@candor.org', 'medical_history_updated', dataObj));
        const sanitizedObj = processFieldData(obj);
        batch.update(doc(db, 'patients', user?.uid, 'general', 'information'), ...sanitizedObj);
        batch.set(doc(collection(db, 'patients', user?.uid, 'activity')), {
          createdAt: Date.now(),
          generalData: false,
          author: 'System',
          text: `Patient added ${lastOption[inputOne] === undefined ? lastOption : lastOption[inputOne]} to their ${inputOne} list`,
        });
        await batch.commit();
      } else {
        const sanitizedObj = processFieldData(obj);
        await updateDoc(doc(db, 'patients', user?.uid, 'general', 'information'), ...sanitizedObj);
      }
      if (nextButton && !isCandor(userType)) handleNext();
    }
  };

  const handleFirstFieldChange = (event) => {
    const { value } = event.target;
    clearError();
    if (value.includes('.')) {
      setInputError('Enter Treatment Name Only (No Full Stops)');
    } else {
      setOptions((prevOptions) => {
        const newOptions = [...prevOptions];
        newOptions[0] = value;
        return newOptions;
      });
    }
  };

  const handleNextButton = () => {
    handleNext();
  };

  useEffect(() => {
    setInputError('');
    const array = [];
    Object.entries(formInputs || {}).forEach(([key, value]) => {
      if (!(key.startsWith(prepender) && key.endsWith(appender) && value)) {
        return;
      }
      // TODO: Might need to update this if someone refactors the file
      // This might cause an issue if appender exist somewhere in the key
      // Ref: https://github.com/Candor-Medical/Candor-React/pull/1066/files/955885d0b592978354e889f29870a384f0fc61b7#r1656381789
      const trimmedKey = key.replace(prepender, '').replace(appender, '');
      array.unshift(trimmedKey);
    });
    array.unshift('');
    setOptions(array);
    setDataLoaded(true);
  }, [currentFormObject, formInputs, inputOne, inputTwo, prepender, appender]);

  if (dataLoaded) {
    return (
      <ExpandableFormElementPresentation
        currentFormObject={currentFormObject}
        inputValue={formInputs[currentFormObject.fieldText]}
        handlePrev={handlePrev}
        handleFirstFieldChange={handleFirstFieldChange}
        handleAddElement={handleAddElement}
        handleRemoveElement={handleRemoveElement}
        options={options}
        inputError={inputError}
        handleNextButton={handleNextButton}
        simpleView={simpleView}
      />
    );
  }
  return <Loading />;
};

export default ExpandableFormElement;
