import React, { useContext, useEffect } from 'react';

import PaymentContext from '../PaymentContext';
import CustomPayment from './CustomPayment';
import { PAYMENT_PROCESSORS, getPaymentProcessor } from '../paymentHelpers';

const { NOVATTI, TILL } = PAYMENT_PROCESSORS;

const paymentConfig = {
  [NOVATTI]: {
    functionName: 'novattiCreateOrderCustom',
    paymentCollectionName: 'novattiOrders',
    iFrameUrlAddress: 'iframe_checkout',
  },
  [TILL]: {
    functionName: 'tillCreateOrderCustom',
    paymentCollectionName: 'tillOrderAttempts',
    iFrameUrlAddress: 'redirectUrl',
  },
};

const CustomPaymentContainer = () => {
  const { paymentProcessor, setPaymentProcessor } = useContext(PaymentContext);

  useEffect(() => {
    const initialLoad = async () => {
      const tempPaymentProcessor = await getPaymentProcessor();
      setPaymentProcessor(tempPaymentProcessor);
    };
    initialLoad();
  }, [setPaymentProcessor]);

  const config = paymentConfig[paymentProcessor];

  if (config) {
    return (
      <CustomPayment
        functionName={config.functionName}
        paymentCollectionName={config.paymentCollectionName}
        iFrameUrlAddress={config.iFrameUrlAddress}
      />
    );
  }
  return null;
};

export default CustomPaymentContainer;
