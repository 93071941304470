import React, { useContext } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Button, Container, Typography, Checkbox, FormControlLabel, FormGroup, Box } from '@mui/material';

import { AuthContext } from '../../../../auth-context';
import { isCandor, isPatient } from '../../../../utils/roles';
import { getFormsButtonText } from '../../../../utils/button-messages';

const PREFIX = 'CheckboxFormElementPresentation';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  accountGrid: `${PREFIX}-accountGrid`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  input: `${PREFIX}-input`,
  form: `${PREFIX}-form`,
  label: `${PREFIX}-label`,
  checkbox: `${PREFIX}-checkbox`,
  button: `${PREFIX}-button`,
  candorPaper: `${PREFIX}-candorPaper`,
  candorHeading: `${PREFIX}-candorHeading`,
  candorSubheading: `${PREFIX}-candorSubheading`,
};

const Root = styled('div')(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyConter: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    flexWrap: 'nowrap',
    minHeight: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column',
    marginBottom: '40px',
    marginTop: 20,
  },

  [`& .${classes.accountGrid}`]: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    direction: 'column',
    margin: '20px 0px',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '100%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
    width: '300px',
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },

  [`& .${classes.label}`]: {
    margin: '10px 0px',
    padding: '10px',
    textAlign: 'center',
    width: '310px',
    '@media (max-width: 600px)': {
      width: '280px',
    },
    '@media (max-width: 480px)': {
      width: '230px',
    },
    '@media (max-width: 400px)': {
      width: '190px',
    },
  },

  [`& .${classes.checkbox}`]: {
    display: 'none',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '130px',
    display: 'block',
  },

  [`& .${classes.candorPaper}`]: {
    boxShadow: 'unset',
    borderBottom: '1px solid rgb(0 0 0 / 20%)',
    borderRadius: 'unset',
    padding: '0px 0 20px',
    width: '100%',
    maxWidth: '600px',
    '@media (max-width: 600px)': {
      padding: '30px',
    },
  },

  [`& .${classes.candorHeading}`]: {
    textAlign: 'left',
    fontWeight: 'bold',
    marginBottom: 15,
  },

  [`& .${classes.candorSubheading}`]: {
    textAlign: 'left',
    margin: '0 0px 10px 0px',
  },
}));

const StyledFormControlLabel = FormControlLabel;

const CheckboxFormElementPresentation = (props) => {
  const { isLoading, currentFormObject, handleInput, handleChange, checkedOptions, simpleView } = props;

  const { userType } = useContext(AuthContext);

  const buttonText = getFormsButtonText({ isUpdateDetails: false, isLoading });

  const hasTrueValue = (obj) => Object.keys(obj).some((key) => obj[key] === true);

  return (
    <Root>
      {((simpleView && hasTrueValue(checkedOptions)) ||
        (isCandor(userType) && !simpleView) ||
        isPatient(userType) ||
        !userType) && (
        <Container maxWidth="lg">
          <Grid container spacing={0} className={isCandor(userType) ? classes.accountGrid : classes.gridContainer}>
            <Paper className={simpleView ? classes.candorPaper : classes.paper}>
              <form>
                <div>
                  <Typography
                    className={simpleView ? classes.candorHeading : classes.heading}
                    variant={simpleView ? 'body1' : 'h5'}
                    gutterBottom
                    align="center"
                  >
                    {currentFormObject.headingText}
                  </Typography>
                </div>
                {currentFormObject.subheadingText && (
                  <div>
                    <Typography
                      className={simpleView ? classes.candorSubheading : classes.subheading}
                      variant="body1"
                      gutterBottom
                      align="center"
                    >
                      {currentFormObject.subheadingText}
                    </Typography>
                  </div>
                )}
                <div>
                  {simpleView ? (
                    <ul style={{ padding: '0 20px' }}>
                      {currentFormObject.fieldText.map((field, ind) => {
                        const labelVal = currentFormObject.labelText
                          ? currentFormObject.labelText[ind]
                          : field.slice(field.lastIndexOf('.') + 1, field.length);
                        return checkedOptions[field] === true && <li>{labelVal}</li>;
                      })}
                    </ul>
                  ) : (
                    <FormGroup className={classes.form}>
                      {currentFormObject.fieldText.map((field, ind) => {
                        const labelVal = currentFormObject.labelText
                          ? currentFormObject.labelText[ind]
                          : field.slice(field.lastIndexOf('.') + 1, field.length);
                        return (
                          <StyledFormControlLabel
                            key={field}
                            control={
                              <Checkbox
                                checked={checkedOptions[field] === true}
                                onChange={handleChange}
                                name={field}
                                className={classes.checkbox}
                              />
                            }
                            label={
                              <Box
                                key={field}
                                border={2}
                                borderColor="primary.main"
                                className={classes.label}
                                borderRadius="8px"
                              >
                                {labelVal}
                              </Box>
                            }
                            classes={{
                              root: classes.root,
                            }}
                          />
                        );
                      })}
                    </FormGroup>
                  )}
                </div>
                {!isCandor(userType) && (
                  <div>
                    <Button
                      disabled={
                        (currentFormObject.selectOne && !Object.values(checkedOptions).includes(true)) || isLoading
                      }
                      className={classes.button}
                      variant="contained"
                      color="primary"
                      onClick={handleInput}
                    >
                      {buttonText}
                    </Button>
                  </div>
                )}
              </form>
            </Paper>
          </Grid>
        </Container>
      )}
    </Root>
  );
};

export default CheckboxFormElementPresentation;
