import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, CircularProgress, Snackbar, SnackbarContent, Typography } from '@mui/material';

import { AuthContext } from '../../../auth-context';
import AgreementContainer from './agreement-container';
import { useDocument } from '../../../hooks/useDocument';
import { USER_TYPES, allowedUserType } from '../../../utils/constants';

const { PHARMACIST, SUPPLIER } = USER_TYPES;

const PREFIX = 'AgreementBanner';

const classes = {
  snackbar: `${PREFIX}-snackbar`,
  snackbarContent: `${PREFIX}-snackbarContent`,
};

const Root = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',

  [`& .${classes.snackbar}`]: {
    minWidth: '50px',
    margin: 'auto',
    marginLeft: 100,
    '@media (max-width: 1000px)': {
      marginLeft: 'auto',
    },
  },

  [`& .${classes.snackbarContent}`]: {
    backgroundColor: '#303F56',
    minWidth: '50px',
  },
}));

const AgreementBanner = () => {
  const { user, userType } = useContext(AuthContext);

  const [open, setOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [userData, hasLoaded] = useDocument(`patients/${user?.uid}`);

  const hasNoPaymentAgreement = hasLoaded && !userData.acceptedAgreements?.payment && userType === PHARMACIST;
  const hasNoEulaAgreement =
    hasLoaded && !userData.acceptedAgreements?.eula && allowedUserType([PHARMACIST, SUPPLIER], userType);

  const handleSnackbarOpen = (message) => {
    setSnackbarMessage(message);
    setOpen(true);
  };

  return (
    <Root>
      <Typography variant="body1" mb={2}>
        Action Required: Our agreements have been updated. You have an outstanding agreement that needs completing.
      </Typography>
      <Box display="flex" gap="2px">
        {hasNoPaymentAgreement && (
          <AgreementContainer agreementRef="pharmacy_payment" handleSnackbarOpen={handleSnackbarOpen} />
        )}
        {hasNoEulaAgreement && (
          <AgreementContainer
            agreementRef={userType === PHARMACIST ? 'pharmacy_eula' : 'supplier_eula'}
            handleSnackbarOpen={handleSnackbarOpen}
          />
        )}
        {!hasLoaded && <CircularProgress />}
      </Box>
      <Snackbar
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
        className={classes.snackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <SnackbarContent className={classes.snackbarContent} message={<span>{snackbarMessage}</span>} />
      </Snackbar>
    </Root>
  );
};

export default AgreementBanner;
