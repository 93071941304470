import React from 'react';
import PropTypes from 'prop-types';
import { FixedSizeList as List } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';

const VirtualizedList = ({ style, itemCount, row, itemData, itemSize }) => (
  <AutoSizer style={style}>
    {({ height, width }) => (
      <List
        height={height}
        width={width}
        itemSize={itemSize}
        itemCount={itemCount}
        itemData={itemData}
        itemKey={(index) => itemData[index].id}
        overscanCount={10}
      >
        {row}
      </List>
    )}
  </AutoSizer>
);

VirtualizedList.propTypes = {
  style: PropTypes.shape({
    height: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    initialHeight: PropTypes.string,
    initialWidth: PropTypes.string,
  }).isRequired,
  itemCount: PropTypes.number.isRequired,
  row: PropTypes.func.isRequired,
  itemData: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  itemSize: PropTypes.number.isRequired,
};

export default VirtualizedList;
