import React, { useContext, useEffect, useState } from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';

import PaymentContext from '../PaymentContext';

const PromoContainer = () => {
  const { promoCode, setPromoCode, checkoutData, isPromoLoading, setIsPromoLoading } = useContext(PaymentContext);
  const { discount } = checkoutData || {};
  const [isUsingPromoCode, setIsUsingPromoCode] = useState(false);
  const [promoInput, setPromoInput] = useState('');

  const applyPromoCode = () => {
    if (promoCode !== promoInput) {
      setPromoCode(promoInput);
      setIsPromoLoading(true);
    }
  };

  useEffect(() => {
    if (discount) {
      setIsUsingPromoCode(false);
    }
  }, [discount]);

  if (isUsingPromoCode) {
    return (
      <Box
        sx={{
          display: 'flex',
          alignSelf: 'start',
          alignItems: 'center',
          justifyContent: 'flex-start',
          gap: 2,
          m: '5px 0px',
        }}
      >
        <TextField
          label="Promo Code"
          size="small"
          variant="outlined"
          value={promoInput}
          onChange={(e) => setPromoInput(e.target.value)}
        />
        {promoInput && (
          <Button variant="contained" onClick={applyPromoCode} size="small" disabled={isPromoLoading}>
            {isPromoLoading ? 'Loading...' : 'Apply'}
          </Button>
        )}
        {discount === 0 && promoCode && !isPromoLoading && (
          <Typography variant="caption" color="darkred">
            Invalid Promo Code
          </Typography>
        )}
      </Box>
    );
  }

  return (
    <Button
      variant="text"
      sx={{ alignSelf: 'start', position: 'relative', right: 8, textTransform: 'none' }}
      onClick={() => setIsUsingPromoCode(true)}
    >
      Use Promo Code
    </Button>
  );
};

export default PromoContainer;
