import React, { useContext, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { Grid, Paper, Button, Container, Typography, Alert, FormControlLabel, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import { isCandor } from '../../../../utils/roles';
import { AuthContext } from '../../../../auth-context';
import { getFormsButtonText } from '../../../../utils/button-messages';

const PREFIX = 'MedicareCardFormElementPresentation';

const classes = {
  root: `${PREFIX}-root`,
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  accountGrid: `${PREFIX}-accountGrid`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  subheading: `${PREFIX}-subheading`,
  input: `${PREFIX}-input`,
  form: `${PREFIX}-form`,
  label: `${PREFIX}-label`,
  checkbox: `${PREFIX}-checkbox`,
  button: `${PREFIX}-button`,
  inputGrid: `${PREFIX}-inputGrid`,
};

const StyledContainer = styled(Container)(({ theme }) => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyConter: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    flexWrap: 'nowrap',
    minHeight: '60vh',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    marginBottom: '40px',
    marginTop: 20,
  },

  [`& .${classes.accountGrid}`]: {
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    margin: '20px 0px',
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '100%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.subheading}`]: {
    margin: '-10px 0px 20px 0px',
  },

  [`& .${classes.input}`]: {
    margin: '20px 0px',
    width: '300px',
  },

  [`& .${classes.form}`]: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 20,
  },

  [`& .${classes.label}`]: {
    margin: '10px 0px',
    padding: '10px',
    textAlign: 'center',
    width: '310px',
    [theme?.breakpoints?.down('sm')]: {
      width: '280px',
    },
  },

  [`& .${classes.checkbox}`]: {
    display: 'none',
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
  },

  [`& .${classes.inputGrid}`]: {
    padding: '10px 5px ',
  },
}));

const StyledFormControlLabel = FormControlLabel;

const MedicareCardFormElementPresentation = (props) => {
  const {
    currentFormObject,
    handleInput,
    handleChange,
    checkedOptions,
    medicareCardData,
    numberError,
    irnError,
    expiryError,
    setNumberError,
    handleExpiryDate,
    validMedicareFields,
    firstNameError,
    lastNameError,
    isUpdateDetails,
  } = props;

  const { userType } = useContext(AuthContext);
  const [expiryData, setExpiryData] = useState(null);
  useEffect(() => {
    if (!medicareCardData?.expiry) {
      setExpiryData(null);
    } else {
      setExpiryData(moment(medicareCardData?.expiry));
    }
  }, [medicareCardData]);

  const buttonText = getFormsButtonText({ isUpdateDetails, isLoading: false });

  return (
    <StyledContainer maxWidth="lg">
      <Grid container spacing={0} className={isCandor(userType) ? classes.accountGrid : classes.gridContainer}>
        <Paper className={classes.paper}>
          <Grid container spacing={2} className={classes.container}>
            <Grid item xs={12}>
              <Typography className={classes.heading} variant="h5" align="center" gutterBottom>
                Your Medicare Card Information
              </Typography>
            </Grid>
            <Grid item xs={6} className={classes.inputGrid}>
              <TextField
                label="First Name"
                value={medicareCardData?.firstName || ''}
                onChange={(e) => handleChange(e.target.value, 'firstName')}
                variant="outlined"
                fullWidth
                error={firstNameError}
              />
            </Grid>
            <Grid item xs={6} className={classes.inputGrid}>
              <TextField
                label="Last Name"
                value={medicareCardData?.lastName || ''}
                onChange={(e) => handleChange(e.target.value, 'lastName')}
                variant="outlined"
                fullWidth
                error={lastNameError}
              />
            </Grid>
            <Grid item xs={12} className={classes.inputGrid}>
              <TextField
                label="Medicare Number"
                value={medicareCardData?.number || ''}
                onChange={(e) => handleChange(e.target.value.replace(/\s+/g, ''), 'number')}
                variant="outlined"
                inputProps={{ minLength: 10, maxLength: 10, inputMode: 'numeric', pattern: '[0-9]*' }}
                onBlur={(e) => {
                  if (e.target.value.length !== 0 && e.target.value.replace(/\s+/g, '').length < 10)
                    setNumberError(true);
                }}
                fullWidth
                error={numberError}
                errorMessage="Please enter a valid Medicare Card Number"
              />
            </Grid>
            <Grid item xs={4} sm={2} className={classes.inputGrid}>
              <TextField
                label="IRN"
                id="IRN"
                value={medicareCardData?.irn || ''}
                onChange={(e) => handleChange(e.target.value, 'irn')}
                inputProps={{ maxLength: 1 }}
                variant="outlined"
                fullWidth
                error={irnError}
              />
            </Grid>
            <Grid item xs={8} sm={10} className={classes.inputGrid}>
              <DatePicker
                label="Expiry Date"
                minDate={moment().format('YYYY-MM-01')}
                value={expiryData}
                inputFormat="MM/YYYY"
                onChange={(date) => handleExpiryDate(date)}
                views={['month', 'year']}
                slotProps={{
                  textField: {
                    helperText: 'MM/YYYY',
                  },
                }}
                /* eslint-disable react/jsx-props-no-spreading */
                renderInput={(params) => <TextField {...params} fullWidth error={expiryError} />}
              />
            </Grid>

            <Grid item xs={12} className={classes.inputGrid}>
              {firstNameError && <Alert severity="error">Please enter a valid first name</Alert>}
              {lastNameError && <Alert severity="error">Please enter a valid last name</Alert>}
              {numberError && <Alert severity="error">Please enter valid card number</Alert>}
              {irnError && <Alert severity="error">Individual Reference Number must be a single digit number.</Alert>}
              {expiryError && <Alert severity="error">Please enter valid expiry date</Alert>}
            </Grid>
          </Grid>
          <Button
            disabled={
              !validMedicareFields || (currentFormObject.selectOne && !Object.values(checkedOptions).includes(true))
            }
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={handleInput}
          >
            {buttonText}
          </Button>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default MedicareCardFormElementPresentation;
