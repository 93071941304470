import React, { useState } from 'react';
import { Stack, Typography, Button, Paper } from '@mui/material';

import { ModalContainer } from '../../layout';

const MAINTENANCE_MODE = false;

const PaymentMaintenance = () => {
  const [isOpenMaintenanceModal, setIsOpenMaintenanceModal] = useState(true);

  const handleClose = () => {
    setIsOpenMaintenanceModal(false);
  };

  if (!MAINTENANCE_MODE) {
    return null;
  }

  return (
    <ModalContainer
      isOpen={isOpenMaintenanceModal}
      onClose={handleClose}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Paper sx={{ p: 2, m: 1 }}>
        <Stack p={2} alignItems="center">
          <Typography fontWeight="bold" color="primary">
            Current Payment Issues
          </Typography>
          <Typography maxWidth={360} mt={2} textAlign="justify">
            We are aware some users are running into errors at checkout.
          </Typography>
          <Typography maxWidth={360} mt={2} textAlign="justify">
            Our payment provider is experiencing an outage and they are working to have this resolved for us asap.
          </Typography>
          <Typography maxWidth={360} mt={2} textAlign="justify">
            Thank you for your patience. If your payment does not work we will email you when things are back to normal.
          </Typography>
          <Button sx={{ mt: 4 }} onClick={handleClose}>
            Close
          </Button>
        </Stack>
      </Paper>
    </ModalContainer>
  );
};

export default PaymentMaintenance;
