import React, { useState, useContext, useEffect } from 'react';
import { signOut } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db, functions } from '../../../firebase-config';
import { AuthContext } from '../../../auth-context';
import AgreementPresentation from './agreement-presentation';
import { useDocument } from '../../../hooks/useDocument';

const sendAgreements = httpsCallable(functions, 'sendAgreements');

const AgreementContainer = (props) => {
  const { handleSnackbarOpen, acceptedAgreements, accountContext, agreementRef } = props;

  const { user } = useContext(AuthContext);

  const [modalOpen, setModalOpen] = useState(false);
  const [agreementTitle, setAgreementTitle] = useState({});
  const [agreement, setAgreement] = useState([]);
  const [signedName, setSignedName] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userData] = useDocument(`orders/${user?.uid}`);

  const acceptAgreement = async () => {
    setIsSubmitting(true);
    await sendAgreements({ agreement, agreementTitle, signedName, email: user?.email, name: userData?.name || '' });
    // let link = document.createElement('a');
    // link.href = data;
    // link.download = 'agreement.pdf';
    // link.click();

    const date = Date.now();
    await updateDoc(doc(db, 'patients', user?.uid), {
      // TODO: Unsure if this is actually safer.
      // switch to lastIndexOf maybe...?
      [`acceptedAgreements.${agreementRef.replace(/.*_([^_]*)$/, '$1')}`]: date,
    });
    handleSnackbarOpen('Agreement Accepted');
    setModalOpen(false);
    setIsSubmitting(false);
  };

  const declineAgreement = () => {
    setModalOpen(false);
    signOut(auth);
  };

  useEffect(() => {
    const getAgreement = async () => {
      const data = (await getDoc(doc(db, 'agreements', agreementRef))).data() || {};
      const titleData = {
        title: data.title || '',
        subtitle: data.subtitle || '',
        party1: data.party1 || '',
        party1_subtitle: data.party1_subtitle || '',
        party2_subtitle: data.party2_subtitle || '',
        lastUpdated: data.lastUpdated || '',
      };
      if (data.party2_subtitle) {
        const pharmacyData = (await getDoc(doc(db, 'orders', user?.uid, 'information', 'general'))).data();
        titleData.party2 = `${pharmacyData?.legalName || ''} - ABN ${pharmacyData?.abn || ''}`;
      }
      // FIXME: BUG - A & B are alwasy NaN as Object.entries returns an array of arrays
      // so A & B are always arrays and parseInt(A, 10) and parseInt(B, 10) are always NaN
      // Does AggrementContainer not get used or have we just gotten lucky?
      // NOTE: Unsure if we're meant to be sorting based on the key or the value in the array?
      const agreementData = Object.entries(data).sort((a, b) => {
        const numA = parseInt(a, 10);
        const numB = parseInt(b, 10);

        if (Number.isNaN(numA)) return -1;
        if (Number.isNaN(numB)) return 1;

        return numA - numB;
      });
      setAgreement(agreementData);
      setAgreementTitle(titleData);
    };
    getAgreement();
  }, [user, agreementRef]);

  return (
    <AgreementPresentation
      agreementRef={agreementRef}
      isSubmitting={isSubmitting}
      setSignedName={setSignedName}
      signedName={signedName}
      agreementTitle={agreementTitle}
      agreement={agreement}
      modalOpen={modalOpen}
      setModalOpen={setModalOpen}
      acceptAgreement={acceptAgreement}
      declineAgreement={declineAgreement}
      acceptedAgreements={acceptedAgreements}
      accountContext={accountContext}
    />
  );
};

export default AgreementContainer;
