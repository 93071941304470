// FIXME: @JoBeDev -> Who created this monster? Should really refactor this and make it more readable.
// TODO: Add tests for this function
// Convert to recursive function at some point
export default function formatDatabaseValues(infoDocData) {
  // FIXME: Should we return or throw or log error?
  // QUESTION: What should we do if undefined/null is passed to us? Or an empty object?
  if (!infoDocData) {
    return {};
  }

  const obj = {};

  Object.entries(infoDocData).forEach((entry) => {
    const [key, val] = entry;
    Object.entries(val).forEach((nestedValue) => {
      const [subKey, subVal] = nestedValue;
      if (typeof subVal === 'object') {
        Object.entries(subVal).forEach((doubleNestedValue) => {
          const [subSubKey, subSubVal] = doubleNestedValue;
          const objString = `${key}.${subKey}.${subSubKey}`;
          obj[objString] = subSubVal;
        });
      } else {
        const objString = `${key}.${subKey}`;
        obj[objString] = subVal;
      }
    });
  });

  return obj;
}
