import React, { useContext, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Container, Button, Typography, Link } from '@mui/material';
import { addDoc, doc, setDoc, collection } from 'firebase/firestore';

import { db } from '../../../../firebase-config';
import { AuthContext } from '../../../../auth-context';
import createEmailObj from '../../../../functions/create-email-obj';

const PREFIX = 'ExitFormElement';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  button: `${PREFIX}-button`,
  link: `${PREFIX}-link`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 20,
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '150px',
    display: 'block',
    textAlign: 'center',
  },

  [`& .${classes.link}`]: {
    textDecoration: 'none',
  },
}));

const ExitFormElement = (props) => {
  const { currentFormObject, initialRejectionReason, formName, formInputs, buttonText = 'Exit' } = props;

  const { user } = useContext(AuthContext);

  if (initialRejectionReason) {
    switch (initialRejectionReason) {
      case 'female':
        currentFormObject.fieldText = (
          <Typography variant="body1" align="center" gutterBottom>
            We&apos;re sorry, this particular treatment is only available to male patients at this time.
          </Typography>
        );
        break;
      case 'male':
        currentFormObject.fieldText = (
          <Typography variant="body1" align="center" gutterBottom>
            We&apos;re sorry, this particular treatment is only available to female patients at this time.
          </Typography>
        );
        break;
      case 'conditions':
        currentFormObject.fieldText = (
          <Typography variant="body1" align="center" gutterBottom>
            We&apos;re sorry, due to one of your existing medical conditions we are unable to provide this treatment for
            you.
            <br />
            <br />
            If your medical conditions have changed you can update them in the{' '}
            <Link className={classes.link} href="/medical-info">
              medical info
            </Link>{' '}
            section of the Candor portal.
            <br />
            <br />
            If you&apos;d like more information you can speak to your Candor GP or contact{' '}
            <Link className={classes.link} href="mailto: support@candor.org">
              support@candor.org
            </Link>
            .
          </Typography>
        );
        break;
      case 'medications':
        currentFormObject.fieldText = (
          <Typography variant="body1" align="center" gutterBottom>
            We&apos;re sorry, due to one of the medications you are currently taking we are unable to provide this
            treatment for you.
            <br />
            <br />
            If your current medications have changed you can update them in the{' '}
            <Link className={classes.link} href="/medical-info">
              medications
            </Link>{' '}
            section of the Candor portal.
            <br />
            <br />
            If you&apos;d like more information you can speak to your Candor GP or contact{' '}
            <Link className={classes.link} href="mailto: support@candor.org">
              support@candor.org
            </Link>
            .
          </Typography>
        );
        break;
      case 'eye herpes':
        currentFormObject.fieldText = (
          <Typography variant="body1" align="center" gutterBottom>
            We&apos;re sorry, due to your previous diagnosis of eye herpes we are unable to provide this treatment for
            you.
            <br />
            <br />
            If you&apos;d like more information you can speak to your Candor GP or contact{' '}
            <Link className={classes.link} href="mailto: support@candor.org">
              support@candor.org
            </Link>
            .
          </Typography>
        );
        break;
      default:
        <Typography variant="body1" align="center" gutterBottom>
          We&apos;re sorry, you are unable to access this treatment at this time.
          <br />
          <br />
          If you&apos;d like more information you can speak to your Candor GP or contact{' '}
          <Link className={classes.link} href="mailto: support@candor.org">
            support@candor.org
          </Link>
        </Typography>;
    }
  }

  useEffect(() => {
    if (!initialRejectionReason && currentFormObject.creationTest && user) {
      let activityLog;
      if (currentFormObject.activityLog !== undefined) {
        activityLog = currentFormObject.activityLog;
      } else {
        activityLog = currentFormObject.creationTest.toString();
        const firstStrip = activityLog.indexOf('[');
        const lastStrip = activityLog.lastIndexOf(']');
        activityLog = activityLog.substring(firstStrip + 2, lastStrip - 1);
        activityLog = activityLog.replaceAll('.', ' - ');
      }
      setDoc(doc(collection(db, 'patients', user.uid, 'activity')), {
        createdAt: Date.now(),
        generalData: false,
        author: 'System',
        text: `Patient hit an exit boundary at [ ${activityLog} ] in the ${formName} form`,
      });
      if (formInputs['basic info.first form completed']) {
        const dataObj = {
          patientName: `${formInputs['basic info.first name']} ${formInputs['basic info.last name']}`,
          email: user.email,
          updatedData: 'Patient has a new acitivity entry',
        };
        addDoc(collection(db, 'mail'), createEmailObj('nurse@candor.org', 'medical_history_updated', dataObj));
      }
    }
  }, [currentFormObject, formInputs, formName, initialRejectionReason, user]);

  return (
    <StyledContainer maxWidth="lg">
      <Grid
        container
        className={classes.gridContainer}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Paper className={classes.paper}>
          <form>
            <div>{currentFormObject.fieldText}</div>
            <div>
              <Button className={classes.button} variant="contained" color="primary" href="/dashboard">
                {buttonText}
              </Button>
            </div>
          </form>
        </Paper>
      </Grid>
    </StyledContainer>
  );
};

export default ExitFormElement;
