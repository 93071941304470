import React from 'react';
import PropTypes from 'prop-types';
import { FormControl, InputLabel, Select } from '@mui/material';

const Selection = ({ children, value, onChange, label, formClassName, selectClassName, fullWidth }) => {
  if (!label) return null;
  const formattedLabel = label.toLowerCase().replace(' ', '-');
  const isFullWidth = fullWidth || false;
  return (
    <FormControl className={formClassName || ''} fullWidth={isFullWidth}>
      <InputLabel id={`${formattedLabel}-label`}>{label}</InputLabel>
      <Select
        fullWidth={isFullWidth}
        value={value}
        labelId={`${formattedLabel}-label`}
        id={`select-${formattedLabel}`}
        label={label}
        onChange={onChange}
        className={selectClassName || ''}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export default Selection;

Selection.propTypes = {
  fullWidth: PropTypes.bool,
  selectClassName: PropTypes.string,
  formClassName: PropTypes.string,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string || PropTypes.number,
  children: PropTypes.node.isRequired,
};

Selection.defaultProps = {
  fullWidth: false,
  selectClassName: '',
  formClassName: '',
  value: '',
};
