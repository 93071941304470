import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Paper, Container, Typography, Button, Modal, Box } from '@mui/material';
import PaymentContainer from '../../../checkout/PaymentContainer';

const PREFIX = 'PaymentFormElementPresentation';

const classes = {
  container: `${PREFIX}-container`,
  gridContainer: `${PREFIX}-gridContainer`,
  paper: `${PREFIX}-paper`,
  heading: `${PREFIX}-heading`,
  button: `${PREFIX}-button`,
  modalGrid: `${PREFIX}-modalGrid`,
};

const StyledContainer = styled(Container)(() => ({
  [`& .${classes.container}`]: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },

  [`& .${classes.gridContainer}`]: {
    minHeight: '60vh',
    marginTop: 20,
  },

  [`& .${classes.paper}`]: {
    padding: '40px',
    width: '85%',
    minWidth: '500px',
    maxWidth: '700px',
    '@media (max-width: 600px)': {
      padding: '30px',
      maxWidth: '500px',
      minWidth: 'unset',
    },
  },

  [`& .${classes.heading}`]: {
    marginBottom: 30,
  },

  [`& .${classes.button}`]: {
    margin: '30px auto 10px auto',
    width: '200px',
    display: 'block',
  },

  [`& .${classes.modalGrid}`]: {
    margin: '10% auto',
    '@media (max-width: 900px)': {
      margin: '10px auto',
      maxWidth: '600px',
      overflow: 'auto',
    },
    maxWidth: '70%',
    borderRadius: '5px',
    maxHeight: '90vh',
  },
}));

const PaymentFormElementPresentation = (props) => {
  const { handleInput, modalOpen, handleModalClose, stripePrice, formName } = props;

  return (
    <StyledContainer data-testid="payment-form-element-presentation" maxWidth="lg">
      <Grid
        container
        className={classes.gridContainer}
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Paper className={classes.paper}>
          <div>
            <Typography className={classes.heading} variant="h5" align="center" gutterBottom>
              Form Submitted
            </Typography>
          </div>
          <div>
            <Typography variant="body1" align="center" gutterBottom>
              Your form was submitted successfully.
              <br />
              <br />
              Click below to pay your consultation fee.
            </Typography>
          </div>
          <div>
            <Button className={classes.button} variant="contained" color="primary" onClick={handleInput}>
              Proceed to payment
            </Button>
          </div>
        </Paper>
      </Grid>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box
          data-testid="constult-payment-container-modal"
          className={classes.modalGrid}
          sx={{
            margin: '10% auto',
            '@media (max-width: 900px)': {
              margin: '10px auto',
              maxWidth: '600px',
              overflow: 'auto',
            },
            maxWidth: '70%',
            borderRadius: '5px',
            maxHeight: '90vh',
          }}
        >
          <PaymentContainer
            termsRead
            mode="payment"
            type="consult"
            description="Consultation"
            selectedTreatments={[stripePrice]}
            formName={formName}
          />
        </Box>
      </Modal>
    </StyledContainer>
  );
};

export default PaymentFormElementPresentation;
